import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Color } from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import {
    INITIAL_FILTER_PARAMS,
    IRemittanceFilterParams,
    RemittanceFilter,
} from "./RemitanceFilter";
import {
    RemittanceResponse,
    RemittanceStatus,
} from "../../services/types/remittance";
import RemittanceService from "../../services/remittanceService";
import fileDownload from "js-file-download";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { LoadingDialog } from "../../components/LoadingDialog";
import {
    CURRENCY_COLUMN_DEFAULTS,
    DATE_COLUMN_DEFAULTS,
    DEFAULT_TABLE_COMPONENTS,
    DEFAULT_TABLE_OPTIONS,
    PageableParams,
    TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import MaterialTable, {
    MaterialTableProps,
    Query,
    QueryResult,
} from "@material-table/core";
import { Delete, GetApp, VisibilityOutlined } from "@material-ui/icons";
import { decodeURLParams, URLParamType } from "../../utils/utils";
import ConfirmationDialog, { ConfirmationDialogHandle } from "../../components/ConfirmationDialog";
import AuthService from "../../services/authService";
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';

export const Remittances = () => {
    const { t } = useTranslation();
    const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
    const currentUser = AuthService.getCurrentUser();

    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IRemittanceFilterParams>({
        ...INITIAL_FILTER_PARAMS,
        ...decodeURLParams(history.location.search, [
            { "sent_date": URLParamType.DATE },
            { "liquidate_date": URLParamType.DATE }
        ])
    });
    const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);

    const tableRef = React.useRef<MaterialTableProps<RemittanceResponse>>();

    const loadData = (query: Query<RemittanceResponse>) =>
        new Promise<QueryResult<RemittanceResponse>>((resolve, reject) => {
            let sentDate = filterParams.sent_date;
            let liquidateDate = filterParams.liquidate_date;

            if (filterParams.status) {
                if ([RemittanceStatus.NOT_SENT].includes(filterParams.status)) {
                    sentDate = null;
                }

                if ([RemittanceStatus.NOT_SENT, RemittanceStatus.AWAITING_SETTLEMENT].includes(filterParams.status)) {
                    sentDate = null;
                    liquidateDate = null;
                }
            }

            RemittanceService.getRemittances(filterParams.number, sentDate,
                liquidateDate, filterParams.status, PageableParams.fromQuery(query))
                .then(response => {
                    resolve({
                        data: response.data,
                        page: query.page,
                        totalCount: response.count,
                    });
                });
        });

    const applyFilter = (params: IRemittanceFilterParams) => {
        setFilterParams(params);
        if (tableRef.current?.onQueryChange) {
            tableRef.current.onQueryChange({
                page: 0,
            } as Query<RemittanceResponse>);
        }
    };

    const refresh = () => {
        if (tableRef.current?.onQueryChange) {
          tableRef.current.onQueryChange({
            page: 0,
          } as Query<RemittanceResponse>);
        }
      };

    const showDetails = (item: RemittanceResponse) => {
        history.push(`remittance/detail/${item.id}`);
    };

    const download = (item: RemittanceResponse) => {
        showLoadingDialog(true);
        RemittanceService.downloadRemittanceXLSX(item.id)
            .then(res => {
                fileDownload(res.data, "remessa.xlsx");
            })
            .catch(err => {
                NotificationManager.error(
                    "Erro ao fazer download do Arquivo de Remessa.",
                    "Arquivo de Remessa"
                );
            })
            .finally(() => {
                showLoadingDialog(false);
            });
    };

    const deleteRemittance = (item: RemittanceResponse) => {
        const dialog = confirmationDialog.current;
        dialog?.confirm(
            `Remover Remessa ${item.number}`,
            <Typography>Você tem certeza que deseja <strong>Remover</strong> esta Remessa?</Typography>,
            "Remover",
            "Cancelar"
          ).then(() => {
            showLoadingDialog(true);
            RemittanceService.deleteRemittance(item.id)
              .then(async (response) => {
                NotificationManager.success("Remessa removida com sucesso.", "Remessa");
                refresh();
                showLoadingDialog(false);
                dialog?.hide();
              })
              .catch((error) => {                
                NotificationManager.error("Não foi possível remover a Remessa.", "Remessa");
                showLoadingDialog(false);
                dialog?.hide();
              });
          }).catch(() => { });
    };

    const mapRemittanceStatusToSeverity = (value: RemittanceStatus): Color => {
        switch (value) {
            case RemittanceStatus.SETTLED:
                return "success";
            case RemittanceStatus.PARTIALLY_SETTLED:
                return "error";
            case RemittanceStatus.SETTLEMENT_FAILED:
                return "error";
            case RemittanceStatus.NOT_SENT:
                return "warning";
            default:
                return "info";
        }
    };

    return (
        <div>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className="page-title"
            >
                <Grid item>
                    <Grid item xs={12}>
                        <Typography variant="h1">Remessas</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomBreadcrumbs
                            pathList={[
                                { label: "Remessas", url: "/remittance" },
                            ]}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <LoadingDialog
                open={isShowLoadingDialog}
                message="Baixando o arquivo, aguarde ..."
            />
            <ConfirmationDialog ref={confirmationDialog} />
            <RemittanceFilter params={filterParams} onFilterChanged={applyFilter} />

            <MaterialTable
                tableRef={tableRef}
                columns={[
                    { field: "id", hidden: true, width: 100 },
                    { field: "number", title: "Número", defaultSort: "desc" },
                    {
                        ...DATE_COLUMN_DEFAULTS,
                        emptyValue: "---",
                        field: "sent_date",
                        title: "Dt. Envio",
                    },
                    {
                        ...DATE_COLUMN_DEFAULTS,
                        emptyValue: "---",
                        field: "liquidate_date",
                        title: "Dt. Liquidação",
                    },
                    {
                        field: "cooperative",
                        title: "Possui Cooperativa",
                        render: (rowData, type) => (
                            <Alert
                            icon={false}
                            severity="info"
                            >
                               {rowData.cooperative ? "Sim" : "Não"}
                            </Alert>
                        ),
                    },
                    {
                        field: "transactions_count",
                        title: "Transações",
                        align: "right",
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "value",
                        title: "Valor",
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "taxes",
                        title: "Taxas",
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "total",
                        title: "Total",
                    },
                    {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "paid_value",
                        title: "Vlr. Repassado",
                    },
                    {
                        field: "status",
                        title: "Situação",
                        render: (rowData, type) => (
                            <Alert
                                icon={false}
                                severity={mapRemittanceStatusToSeverity(
                                    rowData.status
                                )}
                            >
                                {t("remittance_status." + rowData.status)}
                            </Alert>
                        ),
                    },
                ]}
                data={loadData}
                actions={[
                    {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                            showDetails(rowData as RemittanceResponse),
                    },          
                    {
                        icon: () => <GetApp />,
                        tooltip: "Baixar Arquivo de Remessa",
                        onClick: (event, rowData) =>
                            download(rowData as RemittanceResponse),
                    },
                    (rowData) => rowData.status === RemittanceStatus.NOT_SENT && currentUser.user_type === "ADMIN" ?
                    {
                        icon: () => <Delete />,
                        tooltip: "Remover Remessa",
                        onClick: (event, rowData) =>
                            deleteRemittance(rowData as RemittanceResponse),
                    } 
                    : 
                    { 
                        icon: () => <Delete/>,
                        onClick: () => {},
                        hidden: true,  
                    },
                ]}
                components={DEFAULT_TABLE_COMPONENTS}
                localization={TABLE_L10N_PTBR}
                options={DEFAULT_TABLE_OPTIONS}
            />
        </div>
    );
};
