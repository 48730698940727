import React, { createContext, useReducer, useState } from "react";
import type { FC, ReactNode } from "react";
import { SlotResponse } from "../../../services/types/dedicated";
import DedicatedService from "../../../services/dedicatedService";
import { debounce } from "lodash";
import { IPageableParams } from "../../../services/types/common";

interface CourierScheduleState {
  slotList: SlotResponse[];
}

interface CourierScheduleContextValue extends CourierScheduleState {
  loadAvailableSlotsRequests: (regionsId: number[], startDate: Date, endDate: Date) => Promise<void>;
  closeSlot: (slotId: number, done: boolean) => Promise<void>;
  setSlotCourier: (slotId: number, courierId: number) => Promise<void>;
  removeCourierFromSlot: (slotId: number) => Promise<void>;
}

interface CourierScheduleProviderProps {
  children: ReactNode;
}

enum CourierScheduleActions {
  LOAD_SLOTS,
  CLOSE_SLOT,
  SET_COURIER,
  REMOVE_COURIER
}

type LoadSlotsAction = {
  type: CourierScheduleActions.LOAD_SLOTS;
  payload: {
    slotList: SlotResponse[];
  };
};

type CloseSlotAction = {
  type: CourierScheduleActions.CLOSE_SLOT;
  payload: {
    slotList?: SlotResponse[];
  };
};

type SetCourierAction = {
  type: CourierScheduleActions.SET_COURIER;
  payload: {
    slotList?: SlotResponse[];
  };
};

type RemoveCourierAction = {
  type: CourierScheduleActions.REMOVE_COURIER;
  payload: {
    slotList?: SlotResponse[];
  };
};

type Action = LoadSlotsAction | CloseSlotAction | SetCourierAction | RemoveCourierAction;

const INITIAL_COURIER_SCHEDULE_STATE: CourierScheduleState = {
  slotList: [],
};

const reducer = (state: CourierScheduleState, action: Action): CourierScheduleState => {
  switch (action.type) {
    case CourierScheduleActions.LOAD_SLOTS: {
      const { slotList } = action.payload;
      return {
        ...state,
        slotList: slotList,
      };
    }
    case CourierScheduleActions.CLOSE_SLOT: {
      const { slotList } = action.payload;
      return {
        ...state,
        slotList: slotList ? slotList : []
      };
    }
    case CourierScheduleActions.SET_COURIER: {
      const { slotList } = action.payload;
      return {
        ...state,
        slotList: slotList ? slotList : []
      };
    }
    case CourierScheduleActions.REMOVE_COURIER: {
      const { slotList } = action.payload;
      return {
        ...state,
        slotList: slotList ? slotList : []
      };
    }
    default: {
      return { ...state };
    }
  }
};

const CourierScheduleContext = createContext<CourierScheduleContextValue>({
  ...INITIAL_COURIER_SCHEDULE_STATE,
  loadAvailableSlotsRequests: (regionsId: number[], startDate: Date, endDate: Date) => Promise.reject(),
  closeSlot: (slotId: number, done: boolean) => Promise.reject(),
  setSlotCourier: (slotId: number, courierId: number) => Promise.reject(),
  removeCourierFromSlot: (slotId: number) => Promise.reject()
});

export const CourierScheduleContextProvider: FC<CourierScheduleProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_COURIER_SCHEDULE_STATE);

  const [regionsId, setRegionsId] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();


  function refreshSlots(): Promise<SlotResponse[]> {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 400,
      ordering: null
    };

    return new Promise((resolve, reject) => {
      if (startDate && endDate) {
        DedicatedService.getSlots(regionsId, startDate, endDate, DEFAULT_PAGINATION).then((response) => {
          resolve(response.data);
        }).catch((error) => reject(error));
      } else {
        resolve([]);
      }
    });
  }

  /*
   * Exposed functions
   */
  const loadAvailableSlotsRequests = React.useMemo(() => debounce((regionsId: number[], startDate: Date, endDate: Date) => {
    const DEFAULT_PAGINATION: IPageableParams = {
      page: 1,
      page_size: 400,
      ordering: null
    };

    return new Promise<void>((resolve, reject) => {
      DedicatedService.getSlots(regionsId, startDate, endDate, DEFAULT_PAGINATION).then((response) => {
        setRegionsId(regionsId);
        setStartDate(startDate);
        setEndDate(endDate);

        dispatch({
          type: CourierScheduleActions.LOAD_SLOTS,
          payload: {
            slotList: response.data,
          },
        });
        resolve();
      }).catch((error) => reject(error));
    });
  }, 500), []);

  const closeSlot = (slotId: number, done: boolean) => {
    return new Promise<void>((resolve, reject) => {
      DedicatedService.markAsResolved(slotId, done).then(async (response) => {
        dispatch({
          type: CourierScheduleActions.CLOSE_SLOT,
          payload: {
            slotList: await refreshSlots()
          },
        });
        resolve();
      }).catch((error) => reject(error));
    });
  };

  const setSlotCourier = (slotId: number, courierId: number) => {
    return new Promise<void>((resolve, reject) => {
      DedicatedService.addCourierSlot(slotId, courierId).then(async (response) => {
        dispatch({
          type: CourierScheduleActions.SET_COURIER,
          payload: {
            slotList: await refreshSlots()
          },
        });
        resolve();
      }).catch((error) => reject(error));
    });
  };

  const removeCourierFromSlot = (slotId: number) => {
    return new Promise<void>((resolve, reject) => {
      DedicatedService.removeCourierSlot(slotId).then(async (response) => {
        dispatch({
          type: CourierScheduleActions.SET_COURIER,
          payload: {
            slotList: await refreshSlots()
          },
        });
        resolve();
      }).catch((error) => reject(error));
    });
  };

  return (
    <CourierScheduleContext.Provider
      value={{
        ...state,
        loadAvailableSlotsRequests,
        closeSlot,
        setSlotCourier,
        removeCourierFromSlot
      }}>
      {children}
    </CourierScheduleContext.Provider>
  );
};

export default CourierScheduleContext;
