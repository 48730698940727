import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Card,
  CardContent,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import cep from "cep-promise";
import { TextMaskCustom } from "../../components/TextMaskCustom";
import { UploadFile } from "../../components/UploadFile";
import {
  postalCodeMask,
  registrationNumberMask,
  postalCodeMaskRegex,
  noSpecialCharactersRegex,
  noSpecialCharactersAndDigitsRegex,
  mobilePhoneMaskRegex,
  mobilePhoneMask,
} from "../../utils/masks";
import { useHistory } from "react-router";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import { useTranslation } from 'react-i18next';
import { $enum } from "ts-enum-util";
import { Autocomplete } from "@material-ui/lab";
import { FileModalView } from "../../components/FileModalView";
import RegionService from "../../services/regionService";
import { BankAccountType, CourrierType, CreateUpdateCourier, DriversLicenseCategory, PaymentMethod, VehicleLoadCompartmentType, VehicleType } from "../../services/types/courier";
import CourierService from "../../services/courierService";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { CPF_CNPJInput } from "../../components/CPF_CNPJInput";
import CooperativeService from "../../services/cooperativeService";
import { Cooperative } from "../../services/types/cooperative";

interface Bank {
  Code: string;
  Name: string;
  Document: string;
  CreatedAt: string;
  UpdatedAt: null;
  DeletedAt: null;
  IsDeleted: boolean;
}

interface ImageField {
  src: Blob | string | null,
  error?: string,
}

const INITIAL_IMAGES = {
  photo: { src: null, error: "" } as ImageField,
  drivers_license_back_image: { src: null, error: "" } as ImageField,
  drivers_license_front_image: { src: null, error: "" } as ImageField,
  vehicle_registration_back_image: { src: null, error: "" } as ImageField,
  vehicle_registration_front_image: { src: null, error: "" } as ImageField,
  address_proof_image: { src: null, error: "" } as ImageField,
};

interface CreateCourierForm {
  region?: number;
  name?: string;
  email?: string;
  phonenumber?: string;
  registration_number?: string;
  state?: string;
  city?: string;
  postal_code?: string;
  district?: string;
  street?: string;
  address_number?: string;
  address_complement?: string;
  mother_name?: string;
  general_register?: string;
  birthdate?: string | null;
  drivers_license_category?: DriversLicenseCategory | null;
  drivers_license_number?: string;
  drivers_license_expiration?: string | null;
  vehicle_type?: VehicleType | null;
  vehicle_load_compartment_type?: string;
  vehicle_license_plate?: string;
  bank_code?: string;
  bank_name?: string;
  bank_account_type?: BankAccountType | null;
  bank_agency?: string;
  bank_account_code?: string;
  bank_account_code_dv?: string;
  payment_method?: string;
  legal_person_registration_number?: string;
  courier_type?: string;
  cooperative_member_id?: string | null;
  pis?: string | null;
  cooperative?: number | null;
}

const INITIAL_STATE: CreateCourierForm = { courier_type: CourrierType.STANDARD };

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Mínimo de 3 caracteres")
    .max(150, "Limite 150")
    .matches(
      noSpecialCharactersAndDigitsRegex,
      "Números e caracteres especiais não são permitidos"
    )
    .required("Campo obrigatório"),
  registration_number: Yup.string().required("Campo obrigatório"),
  general_register: Yup.string()
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].includes(value),
      then: Yup.string().nullable().required("Campo obrigatório"),
      otherwise: Yup.string().nullable().optional(),
    }),
  payment_method: Yup.string().required("Campo obrigatório"),
  legal_person_registration_number: Yup.string()
    .when("payment_method", {
      is: (value) => [PaymentMethod.LEGAL_PERSON].includes(value),
      then: Yup.string().nullable().required("Campo obrigatório"),
      otherwise: Yup.string().nullable().optional(),
    }),
  email: Yup.string()
    .email("E-mail inválido")
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  phonenumber: Yup.string()
    .min(15, "Telefone inválido")
    .max(15, "Telefone inválido")
    .matches(mobilePhoneMaskRegex, "Telefone inválido")
    .required("Campo obrigatório"),
  birthdate: Yup.string().required("Campo obrigatório"),
  region: Yup.string().nullable().required("Campo obrigatório"),
  postal_code: Yup.string()
    .min(9, "CEP inválido")
    .max(9, "CEP inválido")
    .matches(postalCodeMaskRegex, "CEP inválido")
    .required("Campo obrigatório"),
  drivers_license_number: Yup.string()
    .max(11, "CNH inválido")
    .min(11, "CNH inválido")
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().optional().nullable(),
      otherwise: Yup.string().required("Campo obrigatório"),
    }),
  drivers_license_expiration: Yup.string()
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().nullable().optional(),
      otherwise: Yup.string().nullable().required("Campo obrigatório"),
    }),
  drivers_license_category: Yup.string().when("vehicle_type", {
    is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
    then: Yup.string().optional().nullable(),
    otherwise: Yup.string().required("Campo obrigatório").nullable(),
  }),
  vehicle_type: Yup.string().required("Campo obrigatório").nullable(),
  vehicle_load_compartment_type: Yup.string().when("vehicle_type", {
    is: (value) => [VehicleType.BIKE, VehicleType.MOTORCYCLE].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().optional().nullable(),
  }),
  vehicle_license_plate: Yup.string()
    .min(7, "Placa inválida")
    .max(9, "Placa inválida")
    .matches(
      noSpecialCharactersRegex,
      "Caracteres especiais não são permitidos"
    )
    .when("vehicle_type", {
      is: (value) => [VehicleType.BIKE].indexOf(value) !== -1,
      then: Yup.string().optional().nullable(),
      otherwise: Yup.string().required("Campo obrigatório"),
    }),
  street: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  city: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .required("Campo obrigatório"),
  district: Yup.string()
    .max(200, "Máximo de 200 caracteres excedido")
    .required("Campo obrigatório"),
  address_number: Yup.string()
    .max(10, "Máximo de 10 caracteres excedido")
    .required("Campo obrigatório"),
  address_complement: Yup.string()
    .max(100, "Máximo de 100 caracteres excedido")
    .nullable(),
  state: Yup.string()
    .max(2, "Máximo de 2 caracteres excedido")
    .required("Campo obrigatório"),
  courier_type: Yup.string().nullable().required("Campo obrigatório"),
  bank_code: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.STANDARD].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório")
      .matches(/^\d{2,3}$/, "Informe um código válido.")
      .max(10, "Máximo de 10 caracteres excedido"),
    otherwise: Yup.string().optional().nullable()
  }),
  bank_account_type: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.STANDARD].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().optional().nullable()
  }),
  bank_agency: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.STANDARD].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório")
      .matches(/^\d+$/g, "Informe apenas números.")
      .max(5, "Máximo de 5 caracteres excedido")
      .min(1, "Mínimo de 1 caratere"),
    otherwise: Yup.string().optional().nullable()
  }),
  bank_account_code: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.STANDARD].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório")
      .matches(/^\d+$/g, "Informe apenas números.")
      .max(10, "Máximo de 10 caracteres excedido")
      .min(1, "Mínimo de 1 caratere"),
    otherwise: Yup.string().optional().nullable()
  }),
  bank_account_code_dv: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.STANDARD].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório")
      .matches(/^\d+$/g, "Informe apenas números.")
      .max(3, "Máximo de 3 caracteres excedido")
      .min(1, "Mínimo de 1 caratere"),
    otherwise: Yup.string().optional().nullable()
  }),
  cooperative_member_id: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.COOPERATIVE].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório").max(100, 'Máximo de 100 caracteres excedido.') ,
    otherwise: Yup.string().optional().nullable()
  }),
  pis: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.COOPERATIVE].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório").max(20, 'Máximo de 20 caracteres excedido.'),
    otherwise: Yup.string().optional().nullable()
  }),
  cooperative: Yup.string().when("courier_type", {
    is: (value) => [CourrierType.COOPERATIVE].indexOf(value) !== -1,
    then: Yup.string().required("Campo obrigatório"),
    otherwise: Yup.string().optional().nullable()
  }),
});

interface IImage {
  title: string;
  src: string;
}

export const NewCourier: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [regionList, setRegionList] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);
  const [images, setImages] = useState(INITIAL_IMAGES);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [vehicleConditions, setVehicleConditions] = useState({
    needsCompartment: false,
    needsLicensePlate: false,
  });

  const [isShowImagePreview, setShowImagePreview] = useState(false);
  const [imageToPreview, setImageToPreview] = useState<IImage>({ title: "", src: "" });
  const [cooperatives, setCooperatives] = useState<Cooperative[]>([]);

  useEffect(() => {
    if (banks.length === 0) {
      CourierService.getBanks()
        .then((response) => {
          setBanks(response.data);
        })
        .catch((error) => { });
    }
  }, [banks, selectedBank]);

  const showImagePreview = (title: string, name: string, src: string | ArrayBuffer) => {
    if (src) {
      setImageToPreview({
        title: title,
        src: String(src)
      });
      setShowImagePreview(true);
    }
  }

  const handleCepChange = (cepRequested: string) => {
    cep(cepRequested)
      .then((cepResponse) => {
        formik.setFieldError("postal_code", "");
        formik.setFieldValue("street", cepResponse.street);
        formik.setFieldValue("district", cepResponse.neighborhood);
        formik.setFieldValue("city", cepResponse.city);
        formik.setFieldValue("state", cepResponse.state);
      })
      .catch((error) => {
        formik.setFieldError("postal_code", "Preencha o campo corretamente");
        formik.setFieldValue("street", "");
        formik.setFieldValue("district", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
      });
  };

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    onSubmit: async (courierValues) => {
      let newCourier = {} as CreateUpdateCourier;

      if (validateImages()) {
        const courierKeys = Object.keys(courierValues) as Array<keyof CreateCourierForm>;
        courierKeys.forEach((key: keyof CreateCourierForm) => {
          (newCourier as any)[key] = courierValues[key];
        });

        if (images.photo.src instanceof Blob) {
          newCourier.photo = images.photo.src;
        }
        if (images.drivers_license_front_image.src instanceof Blob) {
          newCourier.drivers_license_front_image =
            images.drivers_license_front_image.src;
        }
        if (images.drivers_license_back_image.src instanceof Blob) {
          newCourier.drivers_license_back_image =
            images.drivers_license_back_image.src;
        }
        if (images.vehicle_registration_front_image.src instanceof Blob) {
          newCourier.vehicle_registration_front_image =
            images.vehicle_registration_front_image.src;
        }
        if (images.vehicle_registration_back_image.src instanceof Blob) {
          newCourier.vehicle_registration_back_image =
            images.vehicle_registration_back_image.src;
        }
        if (images.address_proof_image.src instanceof Blob) {
          newCourier.address_proof_image = images.address_proof_image.src;
        }

        try {
          await CourierService.createCourier(newCourier);
          NotificationManager.success("Entregador salvo com sucesso", "Novo Entregador");
          history.goBack();
        } catch (error) {
          const { status, data } = error.response;
          const errorMsgs: string[] = [];
          let errorMsg = "Ocorreu um erro ao salvar o Entregador";
          if (status === 400) {
            for (var key in data) {
              var value = data[key];
              if (key in courierValues) {
                formik.setFieldError(key, value);
              } else if (key in images) {
                (images as any)[key].error = value;
              } else {
                errorMsgs.push(value);
              }
            }

            if (errorMsgs.length > 0) {
              errorMsg = errorMsgs.join(". \n");
            }
          }
          NotificationManager.error(errorMsg, "Novo Entregador");
        }
      }
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  function validateImageData(image: string | Blob | null): boolean {
    let result = true;

    if (image === null) {
      result = false;
    } else if ((image instanceof Blob) && (image.size === 0)) {
      result = false;
    } else if (image === "") {
      result = false;
    }

    return result;
  }

  const validateImages = () => {
    let valid = true;
    if (!validateImageData(images.photo.src)) {
      setImages({
        ...images,
        photo: { src: images.photo.src, error: "Campo Obrigatório" },
      });
      valid = false;
    }
    if (formik.values.vehicle_type !== VehicleType.BIKE) {
      if (!validateImageData(images.drivers_license_front_image.src)) {
        setImages({
          ...images,
          drivers_license_front_image: {
            src: images.drivers_license_front_image.src,
            error: "Campo Obrigatório",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.drivers_license_back_image.src)) {
        setImages({
          ...images,
          drivers_license_back_image: {
            src: images.drivers_license_back_image.src,
            error: "Campo Obrigatório",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.vehicle_registration_front_image.src)) {
        setImages({
          ...images,
          vehicle_registration_front_image: {
            src: images.vehicle_registration_front_image.src,
            error: "Campo Obrigatório",
          },
        });
        valid = false;
      }
      if (!validateImageData(images.vehicle_registration_back_image.src)) {
        setImages({
          ...images,
          vehicle_registration_back_image: {
            src: images.vehicle_registration_back_image.src,
            error: "Campo Obrigatório",
          },
        });
        valid = false;
      }
    }
    return valid;
  };

  const handleChangeImages = (
    imageField: keyof typeof INITIAL_IMAGES,
    imageValue: string | File | null
  ) => {
    if (imageValue === null) {
      imageValue = new File([], "");
    }
    setImages(oldValues => {
      return {
        ...oldValues,
        [imageField]: { src: imageValue, error: "" },
      }
    });
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    RegionService
      .loadAllRegions({ "ordering": "name" })
      .then((response) => {
        setRegionList(response);
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    CooperativeService
      .getCooperatives()
      .then((response) => {
        setCooperatives(response.data);
      })
      .catch((error) => { });
  }, []);

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Novo Entregador</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Entregadores", url: "/courier" },
                { label: "Novo Entregador", url: "/courier/new" },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <Card style={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h2">Dados pessoais</Typography>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <TextField
                  id="name"
                  label="Nome"
                  variant="outlined"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  helperText={formik.errors.name}
                  error={!!formik.errors.name}
                  InputLabelProps={{ required: true }}
                  autoFocus
                  fullWidth
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  id="mother_name"
                  label="Nome da Mãe"
                  variant="outlined"
                  value={formik.values.mother_name}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.mother_name}
                  helperText={formik.errors.mother_name}
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <TextField
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  value={formik.values.email}
                  onChange={(event) => {
                    formik.setFieldValue(
                      event.target.id,
                      event.target.value.trim()
                    );
                  }}
                  helperText={formik.errors.email}
                  error={!!formik.errors.email}
                  InputLabelProps={{ required: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} >
              <Grid item md sm={6}>
                <TextField
                  id="registration_number"
                  label="CPF"
                  variant="outlined"
                  value={formik.values.registration_number}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.registration_number}
                  helperText={formik.errors.registration_number}
                  InputLabelProps={{ required: true }}
                  InputProps={{
                    inputComponent: RegistrationNumberInput,
                  }}
                />
              </Grid>
              <Grid item md sm={6}>
                <TextField
                  id="general_register"
                  label="RG"
                  variant="outlined"
                  value={formik.values.general_register}
                  onChange={formik.handleChange}
                  fullWidth
                  InputLabelProps={{ required: !vehicleConditions.needsLicensePlate }}
                  error={!!formik.errors.general_register}
                  helperText={formik.errors.general_register}
                />
              </Grid>
              <Grid item md sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formik.errors.payment_method}
                >
                  <InputLabel id="payment_method-label">
                    Pessoa *
                  </InputLabel>
                  <Select
                    labelId="payment_method-label"
                    label="Pessoa"
                    id="payment_method"
                    value={formik.values.payment_method}
                    name="payment_method"
                    onChange={formik.handleChange}
                    required={true}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value={PaymentMethod.PHYSICAL_PERSON}>{t("payment_method." + PaymentMethod.PHYSICAL_PERSON)}</MenuItem>
                    <MenuItem value={PaymentMethod.LEGAL_PERSON}>{t("payment_method." + PaymentMethod.LEGAL_PERSON)}</MenuItem>

                  </Select>
                  <FormHelperText id="payment_method-helper">
                    {formik.errors.payment_method}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md sm={6}>
                <TextField
                  id="legal_person_registration_number"
                  label={"CNPJ"}
                  variant="outlined"
                  value={formik.values.legal_person_registration_number}
                  onChange={formik.handleChange}
                  fullWidth
                  required={formik.values.payment_method === PaymentMethod.LEGAL_PERSON ? true : false}
                  error={!!formik.errors.legal_person_registration_number}
                  helperText={formik.errors.legal_person_registration_number}
                  InputProps={{
                    inputComponent: CPF_CNPJInput,
                  }}
                />
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: "8px" }} container spacing={2}>
              <Grid item md sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formik.errors.courier_type}
                >
                  <InputLabel id="courier_type_label">
                    Tipo do Entregador *
                  </InputLabel>
                  <Select
                    labelId="courier_type_label"
                    label="Tipo do Entregador"
                    id="courier_type"
                    value={formik.values.courier_type}
                    name="courier_type"
                    onChange={formik.handleChange}
                    required={true}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem selected value={CourrierType.STANDARD}>{t("courier_register_type." + CourrierType.STANDARD)}</MenuItem>
                    <MenuItem value={CourrierType.COOPERATIVE}>{t("courier_register_type." + CourrierType.COOPERATIVE)}</MenuItem>

                  </Select>
                  <FormHelperText id="courier_type_helper">
                    {formik.errors.courier_type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md sm={6}>
                <TextField
                  id="birthdate"
                  label="Data de Nascimento"
                  variant="outlined"
                  type="date"
                  value={formik.values.birthdate}
                  onChange={formik.handleChange}
                  helperText={formik.errors.birthdate}
                  error={!!formik.errors.birthdate}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md sm={6}>
                <TextField
                  id="phonenumber"
                  label="Celular"
                  variant="outlined"
                  value={formik.values.phonenumber}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.phonenumber}
                  helperText={formik.errors.phonenumber}
                  InputLabelProps={{ required: true }}
                  InputProps={{
                    inputComponent: PhoneInput,
                  }}
                />
              </Grid>
              <Grid item md sm={12}>
                <Autocomplete
                  id="region"
                  size="small"
                  value={selectedRegion}
                  options={regionList}
                  getOptionLabel={(option) => `${option.name} - ${option.state}`}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedRegion(newValue);
                      formik.setFieldValue("region", newValue.id);
                    } else {
                      setSelectedRegion(null);
                      formik.setFieldValue("region", null);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      name="region"
                      label="Região"
                      variant="outlined"
                      value={formik.values.region}
                      onChange={formik.handleChange}
                      fullWidth
                      error={!!formik.errors.region}
                      helperText={formik.errors.region}
                      InputLabelProps={{ required: true }}
                    />
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {formik.values.courier_type === CourrierType.STANDARD && (
          <Card style={{ marginBottom: "10px" }}>
            <CardContent>
              <Typography variant="h2">Dados bancários</Typography>
              <Grid container spacing={2}>
                <Grid item md={8} sm={6}>
                  <Autocomplete
                    id="bank_code"
                    size="small"
                    value={selectedBank}
                    options={banks}
                    getOptionLabel={(option) => `${option.code} - ${option.name}`}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedBank(newValue as Bank);
                        formik.setFieldValue("bank_code", newValue.code);
                      } else {
                        setSelectedBank(null);
                        formik.setFieldValue("bank_code", null);
                      }
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        name="bank_code"
                        helperText={formik.errors.bank_code}
                        error={!!formik.errors.bank_code}
                        onChange={formik.handleChange}
                        label="Código do Banco *"
                        variant="outlined"
                        fullWidth />
                    }
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!formik.errors.bank_account_type}
                  >
                    <InputLabel id="bank_account_type-label">
                      Tipo de Conta *
                    </InputLabel>
                    <Select
                      labelId="bank_account_type-label"
                      label="Tipo de Conta *"
                      id="bank_account_type"
                      value={formik.values.bank_account_type}
                      name="bank_account_type"
                      onChange={formik.handleChange}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {$enum(BankAccountType).map((bank: string) => {
                        return <MenuItem value={bank}>{t("bank_account_type." + bank)}</MenuItem>
                      })}
                    </Select>
                    <FormHelperText id="bank_account_type-helper">
                      {formik.errors.bank_account_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6}>
                  <TextField
                    id="bank_agency"
                    label="Agência *"
                    variant="outlined"
                    value={formik.values.bank_agency}
                    onChange={formik.handleChange}
                    helperText={formik.errors.bank_agency}
                    error={!!formik.errors.bank_agency}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    id="bank_account_code"
                    label="Número da Conta *"
                    variant="outlined"
                    value={formik.values.bank_account_code}
                    onChange={formik.handleChange}
                    fullWidth
                    error={!!formik.errors.bank_account_code}
                    helperText={formik.errors.bank_account_code}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    id="bank_account_code_dv"
                    label="Dígito da Conta *"
                    variant="outlined"
                    value={formik.values.bank_account_code_dv}
                    onChange={formik.handleChange}
                    fullWidth
                    error={!!formik.errors.bank_account_code_dv}
                    helperText={formik.errors.bank_account_code_dv}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Os campos com (*) são de preenchimento obrigatório.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        {formik.values.courier_type === CourrierType.COOPERATIVE && (
          <Card style={{ marginBottom: "10px" }}>
            <CardContent>
              <Typography variant="h2">Cooperativa</Typography>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6}>
                  <TextField
                    id="cooperative_member_id"
                    label="Matrícula na Cooperativa *"
                    variant="outlined"
                    value={formik.values.cooperative_member_id}
                    onChange={formik.handleChange}
                    helperText={formik.errors.cooperative_member_id}
                    error={!!formik.errors.cooperative_member_id}
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <TextField
                    id="pis"
                    label="PIS *"
                    variant="outlined"
                    value={formik.values.pis}
                    onChange={formik.handleChange}
                    fullWidth
                    error={!!formik.errors.pis}
                    helperText={formik.errors.pis}
                  />
                </Grid>
                <Grid item md={4} sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!formik.errors.cooperative}
                  >
                    <InputLabel id="cooperative-label">
                      Cooperativa *
                    </InputLabel>
                    <Select
                      labelId="cooperative-label"
                      label="Cooperativa"
                      id="cooperative"
                      value={formik.values.cooperative}
                      name="cooperative"
                      onChange={formik.handleChange}
                      required={true}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      {cooperatives.map((cooperativa) => {
                        return (
                          <MenuItem value={cooperativa.id}>{cooperativa.name}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText id="payment_method-helper">
                      {formik.errors.payment_method}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Os campos com (*) são de preenchimento obrigatório.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <Card style={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h2">Dados do Veículo</Typography>
            <Grid container spacing={2}>
              <Grid item md={4} xl={2} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formik.errors.vehicle_type}
                >
                  <InputLabel id="vehicle_type-label">
                    Tipo de veículo *
                  </InputLabel>
                  <Select
                    labelId="vehicle_type-label"
                    label="Tipo de veículo *"
                    id="vehicle_type"
                    name="vehicle_type"
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    value={formik.values.vehicle_type}
                    onChange={(e) => {
                      formik.handleChange(e);
                      switch (e.target.value) {
                        case VehicleType.BIKE:
                          formik.setFieldValue("vehicle_license_plate", "");

                          setVehicleConditions((prevState) => {
                            return {
                              needsCompartment: true,
                              needsLicensePlate: false,
                            };
                          });
                          break;
                        case VehicleType.MOTORCYCLE:
                          setVehicleConditions((prevState) => {
                            return {
                              needsCompartment: true,
                              needsLicensePlate: true,
                            };
                          });
                          break;
                        default:
                          formik.setFieldValue(
                            "vehicle_load_compartment_type",
                            ""
                          );
                          setVehicleConditions((prevState) => {
                            return {
                              needsCompartment: false,
                              needsLicensePlate: true,
                            };
                          });
                          break;
                      }
                    }}
                    error={!!formik.errors.vehicle_type}
                  >
                    {$enum(VehicleType).map((type) => {
                      return <MenuItem value={type}>{t("vehicle_type." + type)}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText id="vehicle_type-helper">
                    {formik.errors.vehicle_type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xl={2} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formik.errors.vehicle_load_compartment_type}
                >
                  <InputLabel id="drivers_license_category-label">
                    Tipo do compartimento de carga
                    {vehicleConditions.needsCompartment ? " *" : ""}
                  </InputLabel>
                  <Select
                    labelId="vehicle_load_compartment_type-label"
                    label="Tipo do compartimento de carga *"
                    id="vehicle_load_compartment_type"
                    name="vehicle_load_compartment_type"
                    disabled={!vehicleConditions.needsCompartment}
                    value={formik.values.vehicle_load_compartment_type}
                    onChange={formik.handleChange}
                    error={!!formik.errors.vehicle_type}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {$enum(VehicleLoadCompartmentType).map((type) => {
                      return <MenuItem value={type}>{t("vehicle_load_compartment_type." + type)}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText id="vehicle_load_compartment_type-helper">
                    {formik.errors.vehicle_load_compartment_type}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={4} xl={2} sm={6}>
                <TextField
                  id="vehicle_license_plate"
                  label={`Placa ${vehicleConditions.needsLicensePlate ? "*" : ""}`}
                  variant="outlined"
                  disabled={!vehicleConditions.needsLicensePlate}
                  value={formik.values.vehicle_license_plate}
                  onChange={formik.handleChange}
                  helperText={formik.errors.vehicle_license_plate}
                  error={!!formik.errors.vehicle_license_plate}
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xl={2} sm={6}>
                <TextField
                  id="drivers_license_number"
                  label={vehicleConditions.needsLicensePlate ? "CNH *" : "CNH"}
                  variant="outlined"
                  value={formik.values.drivers_license_number}
                  onChange={formik.handleChange}
                  helperText={formik.errors.drivers_license_number}
                  error={!!formik.errors.drivers_license_number}
                  fullWidth
                />
              </Grid>
              <Grid item md={4} xl={2} sm={6}>
                <TextField
                  id="drivers_license_expiration"
                  label={vehicleConditions.needsLicensePlate ? "Validade da CNH *" : "Validade da CNH"}
                  variant="outlined"
                  type="date"
                  value={formik.values.drivers_license_expiration}
                  onChange={formik.handleChange}
                  helperText={formik.errors.drivers_license_expiration}
                  error={!!formik.errors.drivers_license_expiration}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={4} xl={2} sm={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!formik.errors.drivers_license_category}
                >
                  <InputLabel id="drivers_license_category-label">
                    {vehicleConditions.needsLicensePlate
                      ? "Categoria da CNH *"
                      : "Categoria da CNH"}
                  </InputLabel>
                  <Select
                    labelId="drivers_license_category-label"
                    label="Categoria da CNH"
                    id="drivers_license_category"
                    value={formik.values.drivers_license_category}
                    name="drivers_license_category"
                    onChange={formik.handleChange}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                  >
                    {$enum(DriversLicenseCategory).map((category) => {
                      return <MenuItem value={category}>{category}</MenuItem>
                    })}
                  </Select>
                  <FormHelperText id="drivers_license_category-helper">
                    {formik.errors.drivers_license_category}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h2">Endereço</Typography>
            <Grid container spacing={2}>
              <Grid item md={3} sm={6}>
                <TextField
                  id="postal_code"
                  label="CEP"
                  variant="outlined"
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={(e) => handleCepChange(e.target.value)}
                  fullWidth
                  error={!!formik.errors.postal_code}
                  helperText={formik.errors.postal_code}
                  InputProps={{
                    inputComponent: PostCodeInput,
                  }}
                />
              </Grid>
              <Grid item md={7} sm={6}>
                <TextField
                  id="street"
                  label="Rua *"
                  variant="outlined"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  fullWidth
                  error={!!formik.errors.street}
                  helperText={formik.errors.street}
                />
              </Grid>
              <Grid item md={2} sm={6}>
                <TextField
                  id="address_number"
                  label="Número *"
                  variant="outlined"
                  value={formik.values.address_number}
                  onChange={formik.handleChange}
                  helperText={formik.errors.address_number}
                  error={!!formik.errors.address_number}
                  fullWidth
                />
              </Grid>
              <Grid item md={4} sm={6}>
                <TextField
                  id="address_complement"
                  label="Complemento"
                  variant="outlined"
                  value={formik.values.address_complement}
                  error={!!formik.errors.address_complement}
                  helperText={formik.errors.address_complement}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <TextField
                  id="district"
                  label="Bairro *"
                  variant="outlined"
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  helperText={formik.errors.district}
                  error={!!formik.errors.district}
                  fullWidth
                />
              </Grid>
              <Grid item md={3} sm={6}>
                <TextField
                  id="city"
                  label="Cidade *"
                  variant="outlined"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  helperText={formik.errors.city}
                  error={!!formik.errors.city}
                  fullWidth
                />
              </Grid>
              <Grid item md={2} sm={6}>
                <TextField
                  id="state"
                  label="Estado *"
                  variant="outlined"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  helperText={formik.errors.state}
                  error={!!formik.errors.state}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "10px" }}>
          <CardContent>
            <Typography variant="h2">Documentos anexados</Typography>
            <Grid container spacing={2}>
              <Grid item xl={2} lg={2} md={2} sm={6} xs={3}>
                <UploadFile
                  error={!!images.photo.error}
                  name="photo"
                  onChange={(image) => handleChangeImages("photo", image)}
                  onTitleClick={showImagePreview}
                  title="Foto" />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <UploadFile
                  error={!!images.drivers_license_front_image.error}
                  name="drivers_license_front_image"
                  onChange={(image) => handleChangeImages("drivers_license_front_image", image)}
                  onTitleClick={showImagePreview}
                  title="CNH/RG Frente" />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <UploadFile
                  error={!!images.drivers_license_back_image.error}
                  name="drivers_license_back_image"
                  onChange={(image) => handleChangeImages("drivers_license_back_image", image)}
                  onTitleClick={showImagePreview}
                  title="CNH/RG Verso" />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <UploadFile
                  error={!!images.vehicle_registration_front_image.error}
                  name="vehicle_registration_front_image"
                  onChange={(image) => handleChangeImages("vehicle_registration_front_image", image)}
                  onTitleClick={showImagePreview}
                  title="CRLV Frente" />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <UploadFile
                  error={!!images.vehicle_registration_back_image.error}
                  name="vehicle_registration_back_image"
                  onChange={(image) => handleChangeImages("vehicle_registration_back_image", image)}
                  onTitleClick={showImagePreview}
                  title="CRLV Verso" />
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <UploadFile
                  error={!!images.address_proof_image.error}
                  name="address_proof_image"
                  onChange={(image) => handleChangeImages("address_proof_image", image)}
                  onTitleClick={showImagePreview}
                  title="Comprovante de Residência" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="caption">
                  Os campos com (*) são de preenchimento obrigatório.
                </Typography>
              </Grid>
            </Grid>
            <FileModalView
              isOpen={isShowImagePreview}
              closeModal={() => setShowImagePreview(false)}
              title={imageToPreview.title}
              src={imageToPreview.src}
            />
          </CardContent>
        </Card>

        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const RegistrationNumberInput = (props: any) => (
  <TextMaskCustom {...props} mask={registrationNumberMask} />
);

const PostCodeInput = (props: any) => (
  <TextMaskCustom {...props} mask={postalCodeMask} />
);

const PhoneInput = (props: any) => (
  <TextMaskCustom {...props} mask={mobilePhoneMask} />
);
