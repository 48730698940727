import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  capitalize,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { format } from "date-fns";
import { CURRENT_DATE_FORMAT } from "../../../i18n";
import { CourierDetails, CourrierType } from "../../../services/types/courier";
import { formatCPFCNPJ, formatPhoneNumber } from "../../../utils/formatters";
import CooperativeService from "../../../services/cooperativeService";
import { Cooperative } from "../../../services/types/cooperative";

interface ICourierDetailDataProps {
  courier?: CourierDetails;
}

export const CourierDetailData: React.FC<ICourierDetailDataProps> = ({
  courier,
}) => {
  const { t } = useTranslation();
  const [cooperative, setCooperative] = useState<Cooperative>();

  useEffect(() => {
    if (courier?.courier_type === CourrierType.COOPERATIVE) {
      CooperativeService
        .getCooperative(courier.cooperative)
        .then((response) => {
          setCooperative(response);
        })
        .catch((error) => { });
    }
  }, [courier]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Typography variant="h2">
                Dados pessoais
              </Typography>
              <Table className="table-bordered" >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Data do Cadastro</Typography>
                      <Typography variant="subtitle1">{courier?.created_at ? format(courier.created_at, CURRENT_DATE_FORMAT) : "---"}</Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography>Nome da Mãe</Typography>
                      <Typography variant="subtitle1">{courier?.mother_name}</Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography>Data de Nascimento</Typography>
                      <Typography variant="subtitle1">{courier?.birthdate ? format(courier.birthdate, CURRENT_DATE_FORMAT) : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>CPF</Typography>
                      <Typography variant="subtitle1">{courier?.registration_number ? formatCPFCNPJ(courier?.registration_number) : "---"}</Typography>
                    </TableCell>
                    <TableCell >
                      <Typography>RG</Typography>
                      <Typography variant="subtitle1">{courier?.general_register ? courier.general_register : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Celular</Typography>
                      <Typography variant="subtitle1">{courier?.phonenumber ? formatPhoneNumber(courier?.phonenumber) : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Pessoa</Typography>
                      <Typography variant="subtitle1">{courier?.payment_method ? t("payment_method." + courier?.payment_method) : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>CNPJ</Typography>
                      <Typography variant="subtitle1">{courier?.legal_person_registration_number ? formatCPFCNPJ(courier?.legal_person_registration_number) : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography>E-mail</Typography>
                      <Typography variant="subtitle1">{courier?.email}</Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Typography>Região</Typography>
                      <Typography variant="subtitle1">{courier?.region ? `${capitalize(courier.region.name)} - ${courier.region.state}` : "---"}</Typography>
                    </TableCell>
                  </TableRow>
                  {courier?.drivers_license_number && (<TableRow>
                    <TableCell>
                      <Typography>CNH</Typography>
                      <Typography variant="subtitle1">{courier.drivers_license_number}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Categoria da CNH</Typography>
                      <Typography variant="subtitle1">{courier.drivers_license_category}</Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Typography>Validade da CNH</Typography>
                      <Typography variant="subtitle1">{courier.drivers_license_expiration ? format(courier.drivers_license_expiration, CURRENT_DATE_FORMAT) : "---"}</Typography>
                    </TableCell>
                  </TableRow>)}
                  <TableRow>
                    <TableCell>
                      <Typography>Tipo de Entregador</Typography>
                      <Typography variant="subtitle1">{t('courier_register_type.' + courier?.courier_type)}</Typography>
                    </TableCell>
                    {courier?.courier_type === CourrierType.COOPERATIVE && (
                      <>
                        <TableCell>
                          <Typography>Matrícula na Cooperativa</Typography>
                          <Typography variant="subtitle1">{courier?.cooperative_member_id}</Typography>
                        </TableCell>
                        <TableCell colSpan={3}>
                          <Typography>PIS</Typography>
                          <Typography variant="subtitle1">{courier?.pis}</Typography>
                        </TableCell>
                      </>
                    )}

                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        {courier?.courier_type === CourrierType.STANDARD && (
          <Grid item md={12}>
            <Card>
              <CardContent>
                <Typography variant="h2">
                  Dados bancários
                </Typography>
                <Table className="table-bordered" >
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography>Código do Banco</Typography>
                        <Typography variant="subtitle1">{courier?.bank_code}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Nome do Banco</Typography>
                        <Typography variant="subtitle1">{courier?.bank_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Tipo de Conta</Typography>
                        <Typography variant="subtitle1">{t('bank_account_type.' + courier?.bank_account_type)}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>Agência</Typography>
                        <Typography variant="subtitle1">{courier?.bank_agency}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Número da Conta</Typography>
                        <Typography variant="subtitle1">{courier?.bank_account_code}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Dígito da Conta</Typography>
                        <Typography variant="subtitle1">{courier?.bank_account_code_dv}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        )}
        {courier && courier.courier_type === CourrierType.COOPERATIVE && cooperative && (
          <Grid item md={12}>
            <Card>
              <CardContent>
                <Typography variant="h2">
                  Dados da Cooperativa
                </Typography>
                <Table className="table-bordered" >
                  <TableBody>
                    <TableRow>
                    <TableCell>
                        <Typography>Nome</Typography>
                        <Typography variant="subtitle1">{cooperative?.name}</Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography>CNPJ</Typography>
                        <Typography variant="subtitle1">{cooperative?.registration_number ? formatCPFCNPJ(cooperative?.registration_number) : "---"}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>                 
                      <TableCell>
                        <Typography>Contato</Typography>
                        <Typography variant="subtitle1">{cooperative?.contact_name}</Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography>Telefone P/ Contato</Typography>
                        <Typography variant="subtitle1">{cooperative?.support_phone ? formatPhoneNumber(cooperative?.support_phone) : "---"}</Typography>

                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>Código do Banco</Typography>
                        <Typography variant="subtitle1">{cooperative?.bank_code}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Nome do Banco</Typography>
                        <Typography variant="subtitle1">{cooperative?.bank_name}</Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography>Tipo de Conta</Typography>
                        <Typography variant="subtitle1">{t('bank_account_type.' + cooperative?.bank_account_type)}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography>Agência</Typography>
                        <Typography variant="subtitle1">{cooperative?.bank_agency}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>Número da Conta</Typography>
                        <Typography variant="subtitle1">{cooperative?.bank_account_code}</Typography>
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Typography>Dígito da Conta</Typography>
                        <Typography variant="subtitle1">{cooperative?.bank_account_code_dv}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Typography variant="h2">
                Dados do veículo
              </Typography>
              <Table className="table-bordered" >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Tipo do veículo</Typography>
                      <Typography variant="subtitle1">{courier ? t('vehicle_type.' + courier.vehicle_type) : "---"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Tipo do compartimento de carga</Typography>
                      <Typography variant="subtitle1">
                        {courier?.vehicle_load_compartment_type ? t('vehicle_load_compartment_type.' + courier.vehicle_load_compartment_type) : ""}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Placa</Typography>
                      <Typography variant="subtitle1">
                        {courier?.vehicle_license_plate}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12}>
          <Card>
            <CardContent>
              <Typography variant="h2">Endereço</Typography>
              <Table className="table-bordered" >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>CEP</Typography>
                      <Typography variant="subtitle1">{courier?.postal_code}</Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography>Rua</Typography>
                      <Typography variant="subtitle1">{courier?.street}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Número</Typography>
                      <Typography variant="subtitle1">{courier?.address_number}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Complemento</Typography>
                      <Typography variant="subtitle1">{courier?.address_complement}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Bairro</Typography>
                      <Typography variant="subtitle1">{courier?.district}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Cidade</Typography>
                      <Typography variant="subtitle1">{courier?.city}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Estado</Typography>
                      <Typography variant="subtitle1">{courier?.state}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
