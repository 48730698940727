import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CustomBreadcrumbs } from "../../components/CustomBreadcrumbs";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import numbro from "numbro";
import RemittanceService from "../../services/remittanceService";
import { format, formatISO, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import bancos_brasil from "bancos-brasileiros";
import {
  RemittanceResponse,
  RemittanceStatus,
  TransactionStatus,
} from "../../services/types/remittance";
import DetailTransactionDialog from "./DetailTransactionDialog";
//@ts-ignore
import { NotificationManager } from "react-notifications";
import { LoadingDialog } from "../../components/LoadingDialog";
import fileDownload from "js-file-download";
import {
  GetApp,
  Publish,
  Check,
  Send,
  PostAdd,
  Close,
  VisibilityOutlined,
  Delete,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Color } from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CURRENT_DATE_FORMAT } from "../../i18n";
import {
  CURRENCY_COLUMN_DEFAULTS,
  DEFAULT_TABLE_COMPONENTS,
  DEFAULT_TABLE_OPTIONS,
  TABLE_L10N_PTBR,
} from "../../components/TableTrinkets";
import MaterialTable from "@material-table/core";
import AuthService from "../../services/authService";
import ConfirmationDialog, {
  ConfirmationDialogHandle,
} from "../../components/ConfirmationDialog";
import { formatCPFCNPJ, formatPhoneNumber } from "../../utils/formatters";
import CooperativeService from "../../services/cooperativeService";
import { Cooperative } from "../../services/types/cooperative";

interface IParams {
  remittanceId: string;
}

interface ITransactionsCounts {
  total: number;
  settled: number;
  canceled: number;
}

const INITIAL_TRANSACTIONS_COUNTS: ITransactionsCounts = {
  total: 0,
  settled: 0,
  canceled: 0,
};

interface IMarkAsSentData {
  sentDate: string | null;
}

const INITIAL_SET_SENT_FORM_DATA: IMarkAsSentData = {
  sentDate: null,
};

const validationSchema = Yup.object().shape({
  sentDate: Yup.string().nullable().required("Campo obrigatório"),
});

export const DetailsRemittance: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();

  const confirmationDialog = React.createRef<ConfirmationDialogHandle>();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [isShowTransactionDetails, showTransactionDetails] =
    React.useState(false);
  const [isShowLoadingDialog, showLoadingDialog] = React.useState(false);
  const [isShowSentConfirmationDialog, showSentConfirmationDialog] =
    React.useState(false);
  const [
    isShowDuplicateRemittanceConfirmationDialog,
    showDuplicateRemittanceConfirmationDialog,
  ] = React.useState(false);
  const [
    isShowSendRemittanceConfirmationDialog,
    showSendRemittanceConfirmationDialog,
  ] = React.useState(false);
  const [isShowNewRemittanceDialog, showNewRemittanceDialog] =
    React.useState(false);
  const [loadingDialogMessage, setLoadingDialogMessage] = React.useState("");
  const [selectedTransactionId, setSelectedTransactionId] =
    React.useState<number>();
  const [cooperative, setCooperative] = React.useState<Cooperative>();

  const { remittanceId } = useParams<IParams>();
  const [remittance, setRemittance] = useState<any>({});
  const [newRemittance, setNewRemittance] = useState<RemittanceResponse>();
  const [transactionsCounts, setTransactionsCounts] =
    useState<ITransactionsCounts>(INITIAL_TRANSACTIONS_COUNTS);

  const openTransactionDetails = (transaction: any) => {
    setSelectedTransactionId(Number(transaction.id));
    showTransactionDetails(true);
  };

  const closeTransactionDetails = () => {
    showTransactionDetails(false);
    setSelectedTransactionId(undefined);
  };

  const sendRemittanceResponseFile = async (file: File) => {
    setLoadingDialogMessage("Enviando arquivo, aguarde ...");
    showLoadingDialog(true);
    try {
      await RemittanceService.uploadRemittanceResponseFile(
        Number(remittanceId),
        file
      );
      await refreshData(Number(remittanceId));
      NotificationManager.success(
        "Arquivo de Remessa enviado com sucesso.",
        "Arquivo de Remessa"
      );
    } catch (error) {
      NotificationManager.error(
        "Erro ao enviar Arquivo de Remessa.",
        "Arquivo de Remessa"
      );
    } finally {
      showLoadingDialog(false);
    }
  };

  const handleChangeFile = (event: any) => {
    event.preventDefault();
    let file = event.target.files[0];
    sendRemittanceResponseFile(file);
  };

  const getCooperative = useCallback((id: number) => {
    if (id) {
      CooperativeService.getCooperative(id)
        .then((response) => {
          setCooperative(response);
        })
        .catch((error) => {});
    }
  },[])

  const refreshData = useCallback(async (id: number) => {
    setLoadingDialogMessage("Aguarde ...");
    showLoadingDialog(true);
    try {
      const response: any = await RemittanceService.getRemittance(id);
      setRemittance(response.data);
      if (response.data && response.data.cooperative) {
        getCooperative(response.data.cooperative);
      }
    } catch (error) {
      NotificationManager.error(
        "Erro ao tentar carregar os dados da Remessa.",
        "Remessa"
      );
    } finally {
      showLoadingDialog(false);
    }
  }, [getCooperative]);

  useEffect(() => {
    if (remittance.transactions) {
      const counts: ITransactionsCounts = {
        total: remittance.transactions.length,
        settled: 0,
        canceled: 0,
      };

      if (
        ![
          RemittanceStatus.NOT_SENT,
          RemittanceStatus.AWAITING_SETTLEMENT,
        ].includes(remittance.status)
      ) {
        remittance.transactions.forEach((transaction: any) => {
          if (transaction.status === TransactionStatus.CONFIRMED) {
            counts.settled++;
          } else if (transaction.status === TransactionStatus.CANCELED) {
            counts.canceled++;
          }
        });
      }
      setTransactionsCounts(counts);
    }
  }, [remittance]);

  useEffect(() => {
    refreshData(Number(remittanceId));
  }, [remittanceId, refreshData]);

  const downloadRemmitanceFile = () => {
    setLoadingDialogMessage("Baixando o arquivo, aguarde ...");
    showLoadingDialog(true);
    RemittanceService.downloadRemittanceXLSX(Number(remittanceId))
      .then((res) => {
        fileDownload(res.data, "remessa.xlsx");
      })
      .catch((err) => {
        NotificationManager.error(
          "Erro ao fazer download do Arquivo de Remessa.",
          "Arquivo de Remessa"
        );
      })
      .finally(() => {
        showLoadingDialog(false);
      });
  };

  const formik = useFormik({
    initialValues: INITIAL_SET_SENT_FORM_DATA,
    onSubmit: (values) => {
      closeSentConfirmationDialog();
      if (values.sentDate) {
        setAsSent(parseISO(values.sentDate));
      }
    },
    validationSchema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const closeSentConfirmationDialog = () => {
    showSentConfirmationDialog(false);
  };

  const closeDuplicateRemittanceConfirmationDialog = () => {
    showDuplicateRemittanceConfirmationDialog(false);
  };

  const closeSendRemittanceConfirmationDialog = () => {
    showSendRemittanceConfirmationDialog(false);
  };

  const closeNewRemittanceDialog = () => {
    showNewRemittanceDialog(false);
    setNewRemittance(undefined);
  };

  const showNewRemittanceDetails = () => {
    if (newRemittance) {
      showNewRemittanceDialog(false);
      history.push(`/remittance/detail/${newRemittance.id}`);
    }
  };

  const confirmSentOperation = () => {
    formik.setFieldValue(
      "sentDate",
      formatISO(new Date(), { representation: "date" })
    );
    showSentConfirmationDialog(true);
  };

  const confirmSendOperation = () => {
    showSendRemittanceConfirmationDialog(true);
  };

  const confirmDuplicateRemittanceOperation = () => {
    showDuplicateRemittanceConfirmationDialog(true);
  };

  const sendRemittance = async () => {
    try {
      await RemittanceService.sendRemittance(Number(remittanceId));
      await refreshData(Number(remittanceId));
      closeSendRemittanceConfirmationDialog();
      NotificationManager.success("Remessa enviada com sucesso.", "Remessa");
    } catch (error) {
      NotificationManager.error("Erro ao Enviar Remessa.", "Remessa");
    }
  };

  const setAsSent = async (sentDate: Date) => {
    try {
      await RemittanceService.setRemittanceAsSent(
        Number(remittanceId),
        sentDate
      );
      await refreshData(Number(remittanceId));
      NotificationManager.success(
        "Arquivo de Remessa enviado com sucesso.",
        "Arquivo de Remessa"
      );
    } catch (error) {
      NotificationManager.error(
        "Erro ao Marcar Remessa como Enviada.",
        "Remessa"
      );
    }
  };

  const duplicateRemittance = async () => {
    setLoadingDialogMessage("Criando Remessa Complementar, aguarde ...");
    showLoadingDialog(true);
    closeDuplicateRemittanceConfirmationDialog();
    try {
      const newRemittance = await RemittanceService.duplicateRemittance(
        Number(remittanceId)
      );
      setNewRemittance(newRemittance);
      NotificationManager.success(
        "Remessa Complementar criada com sucesso.",
        "Remessa Complementar"
      );
      showNewRemittanceDialog(true);
    } catch (error) {
      NotificationManager.error(
        "Erro ao Criar Remessa Complementar.",
        "Remessa"
      );
    } finally {
      showLoadingDialog(false);
    }
  };

  const deleteRemittance = () => {
    const dialog = confirmationDialog.current;
    dialog
      ?.confirm(
        `Remover Remessa ${remittance.number}`,
        <Typography>
          Você tem certeza que deseja <strong>Remover</strong> esta Remessa?
        </Typography>,
        "Remover",
        "Cancelar"
      )
      .then(() => {
        showLoadingDialog(true);
        RemittanceService.deleteRemittance(Number(remittanceId))
          .then(async (response) => {
            NotificationManager.success(
              "Remessa removida com sucesso.",
              "Remessa"
            );
            showLoadingDialog(false);
            dialog?.hide();
            history.goBack();
          })
          .catch((error) => {
            NotificationManager.error(
              "Não foi possível remover a Remessa.",
              "Remessa"
            );
            showLoadingDialog(false);
            dialog?.hide();
          });
      })
      .catch(() => {});
  };

  const mapTransactionStatusToSeverity = (value: TransactionStatus): Color => {
    switch (value) {
      case TransactionStatus.CONFIRMED:
        return "success";
      case TransactionStatus.CANCELED:
        return "error";
      default:
        return "info";
    }
  };

  return (
    <React.Fragment>
      <input
        id="transactions_file"
        name="transactions_file"
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={handleChangeFile}
        multiple={false}
        accept="*/*"
      />
      <LoadingDialog
        open={isShowLoadingDialog}
        message={loadingDialogMessage}
      />
      <ConfirmationDialog ref={confirmationDialog} />

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={isShowSentConfirmationDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Marcar Remessa como Enviada
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <DialogContentText>
              Informe a data que a Remessa foi enviada para processamento.
            </DialogContentText>
            <TextField
              id="sentDate"
              name="sentDate"
              label="Data do Envio"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.sentDate}
              onChange={formik.handleChange}
              helperText={formik.errors.sentDate}
              error={!!formik.errors.sentDate}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSentConfirmationDialog}>Cancelar</Button>
            <Button type="submit" color="primary">
              Marcar como Enviada
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={isShowDuplicateRemittanceConfirmationDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Criar Remessa Complementar
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Tem certeza que deseja criar uma nova Remessa baseada nas transações
            canceladas da Remessa atual?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDuplicateRemittanceConfirmationDialog}>
            Cancelar
          </Button>
          <Button color="primary" onClick={duplicateRemittance}>
            Criar Remessa
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={isShowSendRemittanceConfirmationDialog}
      >
        <DialogTitle id="simple-dialog-title">Enviar Remessa</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Tem certeza que deseja enviar esta Remessa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSendRemittanceConfirmationDialog}>
            Cancelar
          </Button>
          <Button color="primary" onClick={sendRemittance}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="simple-dialog-title"
        open={isShowNewRemittanceDialog}
      >
        <DialogTitle disableTypography id="simple-dialog-title">
          <Typography variant="h6">Remessa Complementar</Typography>
          <IconButton
            aria-label="close"
            className={classes.dialogCloseButton}
            onClick={closeNewRemittanceDialog}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {newRemittance && (
            <>
              <Typography gutterBottom variant="h5" component="h2">
                Remessa: {newRemittance.number}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xl={3} md={3} sm={12} xs={12}>
                  <Typography>Transações</Typography>
                  <Typography variant="subtitle1">
                    {newRemittance.transactions_count}
                  </Typography>
                </Grid>
                <Grid item xl={3} md={3} sm={12} xs={12}>
                  <Typography>Valor dos Repasses</Typography>
                  <Typography variant="subtitle1">
                    {numbro(Number(newRemittance.value)).formatCurrency()}
                  </Typography>
                </Grid>
                <Grid item xl={3} md={3} sm={12} xs={12}>
                  <Typography>Taxas</Typography>
                  <Typography variant="subtitle1">
                    {numbro(Number(newRemittance.taxes)).formatCurrency()}
                  </Typography>
                </Grid>
                <Grid item xl={3} md={3} sm={12} xs={12}>
                  <Typography>Valor Total</Typography>
                  <Typography variant="subtitle1">
                    {numbro(Number(newRemittance.total)).formatCurrency()}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={showNewRemittanceDetails}>
            Ver Detalhes
          </Button>
        </DialogActions>
      </Dialog>

      <DetailTransactionDialog
        transactionId={selectedTransactionId}
        open={isShowTransactionDetails}
        onCloseAction={closeTransactionDetails}
      ></DetailTransactionDialog>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="page-title"
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography variant="h1">Detalhes da Remessa</Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomBreadcrumbs
              pathList={[
                { label: "Remessa", url: "/remittance" },
                {
                  label: "Detalhes da Remessa",
                  url: `/remittance/detail/${remittanceId}`,
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} spacing={1} justify="flex-end">
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={downloadRemmitanceFile}
              startIcon={<GetApp />}
            >
              Arquivo de Remessa
            </Button>
          </Grid>
          {[RemittanceStatus.NOT_SENT].includes(remittance.status) && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={confirmSentOperation}
                startIcon={<Check />}
              >
                Marcar como Enviada
              </Button>
            </Grid>
          )}
          {[RemittanceStatus.NOT_SENT].includes(remittance.status) && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={confirmSendOperation}
                startIcon={<Send />}
              >
                Enviar
              </Button>
            </Grid>
          )}
          {[RemittanceStatus.PARTIALLY_SETTLED].includes(remittance.status) && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={confirmDuplicateRemittanceOperation}
                startIcon={<PostAdd />}
              >
                Criar Remessa Complementar
              </Button>
            </Grid>
          )}
          {[RemittanceStatus.AWAITING_SETTLEMENT].includes(
            remittance.status
          ) && (
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={(event) => {
                  hiddenFileInput.current?.click();
                }}
                startIcon={<Publish />}
              >
                Arquivo de Retorno
              </Button>
            </Grid>
          )}
          {[RemittanceStatus.NOT_SENT].includes(remittance.status) &&
            currentUser.user_type === "ADMIN" && (
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={deleteRemittance}
                  startIcon={<Delete />}
                >
                  Remover Remessa
                </Button>
              </Grid>
            )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Remessa: {remittance.number} -{" "}
                {t("remittance_status." + remittance.status)}
              </Typography>
              <Grid container spacing={2}>
                <Grid item container xl={4} md={12} spacing={2}>
                  <Grid item xl={4} md={2} sm={4} xs={12}>
                    <Typography>Data da Criação</Typography>
                    <Typography variant="subtitle1">
                      {remittance.created_at
                        ? format(
                            parseISO(remittance.created_at),
                            CURRENT_DATE_FORMAT
                          )
                        : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={4} md={2} sm={4} xs={12}>
                    <Typography>Data do Envio</Typography>
                    <Typography variant="subtitle1">
                      {remittance.sent_date
                        ? format(
                            parseISO(remittance.sent_date),
                            CURRENT_DATE_FORMAT
                          )
                        : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xl={4} md={2} sm={4} xs={12}>
                    <Typography>Data da Liquidação</Typography>
                    <Typography variant="subtitle1">
                      {remittance.liquidate_date
                        ? format(
                            parseISO(remittance.liquidate_date),
                            CURRENT_DATE_FORMAT
                          )
                        : "---"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item container xl={8} md={12} spacing={2}>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography>Valor dos Repasses</Typography>
                    <Typography variant="subtitle1">
                      {numbro(Number(remittance.value)).formatCurrency()}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography>Taxas</Typography>
                    <Typography variant="subtitle1">
                      {numbro(Number(remittance.taxes)).formatCurrency()}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography>Valor Total</Typography>
                    <Typography variant="subtitle1">
                      {numbro(Number(remittance.total)).formatCurrency()}
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography>Valor Repassado</Typography>
                    <Typography variant="subtitle1">
                      {numbro(Number(remittance.paid_value)).formatCurrency()}
                    </Typography>
                  </Grid>
                  {[RemittanceStatus.PARTIALLY_SETTLED].includes(
                    remittance.status
                  ) && (
                    <Grid item xl={2} md={2} sm={4} xs={12}>
                      <Typography>Saldo</Typography>
                      <Typography variant="subtitle1">
                        {numbro(
                          Number(remittance.total) -
                            Number(remittance.paid_value)
                        ).formatCurrency()}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {cooperative && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Dados da Cooperativa: 
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Nome</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.name}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Número de Registro</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.registration_number}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Contato</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.contact_name} 
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Telefone de Contato</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.support_phone ? formatPhoneNumber(cooperative.support_phone) : '---'} 
                    </Typography>
                  </Grid>

                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Tipo de Conta</Typography>
                    <Typography variant="subtitle1">
                      {t("bank_account_type." + cooperative.bank_account_type)} 
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Nome do banco</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.bank_name}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Número da Conta</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.bank_account_code}
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Agência</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.bank_agency} 
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Dígito da Conta</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.bank_account_code_dv} 
                    </Typography>
                  </Grid>
                  <Grid item xl={3} md={3} sm={6} xs={12}>
                    <Typography>Código do Banco</Typography>
                    <Typography variant="subtitle1">
                      {cooperative.bank_code} 
                    </Typography>
                  </Grid>
                 
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item container alignItems="baseline" spacing={2}>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography variant="h5" component="h2">
                      Transações:
                    </Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography gutterBottom>
                      Quantidade: <strong>{transactionsCounts.total}</strong>
                    </Typography>
                    <Typography variant="subtitle1"></Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography gutterBottom>
                      Liquidadas: <strong>{transactionsCounts.settled}</strong>
                    </Typography>
                    <Typography variant="subtitle1"></Typography>
                  </Grid>
                  <Grid item xl={2} md={2} sm={4} xs={12}>
                    <Typography gutterBottom>
                      Canceladas: <strong>{transactionsCounts.canceled}</strong>
                    </Typography>
                    <Typography variant="subtitle1"></Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <MaterialTable
                    columns={[
                      { field: "id", hidden: true, width: 60 },
                      {
                        field: "invoice",
                        title: "Transação",
                        defaultSort: "asc",
                      },
                      { field: "courier_name", title: "Entregador" },
                      {
                        field: "courier_registration_number",
                        title: "CPF",
                        render: (rowData, type) =>
                          rowData.courier_registration_number
                            ? formatCPFCNPJ(rowData.courier_registration_number)
                            : "---",
                      },
                      {
                        field: "bank",
                        title: "Banco",
                        render: (rowData, type) =>
                          rowData.courier_bank_code
                            ? bancos_brasil.find(
                                (item) =>
                                  item.Code === rowData.courier_bank_code
                              )?.Name
                            : "---",
                      },
                      {
                        field: "courier_agency",
                        title: "Agência",
                        render: (rowData, type) =>
                          rowData.courier_agency
                            ? rowData.courier_agency
                            : "---",
                      },
                      {
                        field: "courier_bank_account",
                        title: "Conta",
                        render: (rowData, type) =>
                          rowData.courier_bank_account
                            ? `${rowData.courier_bank_account}-${rowData.courier_bank_account_dv}`
                            : "---",
                      },
                      {
                        ...CURRENCY_COLUMN_DEFAULTS,
                        field: "amount",
                        title: "Valor",
                      },
                      {
                        field: "status",
                        title: "Situação",
                        render: (rowData, type) => (
                          <Alert
                            icon={false}
                            severity={mapTransactionStatusToSeverity(
                              rowData.status
                            )}
                          >
                            {t("transaction_status." + rowData.status)}
                          </Alert>
                        ),
                      },
                    ]}
                    data={remittance.transactions}
                    actions={[
                      {
                        icon: () => <VisibilityOutlined />,
                        tooltip: "Ver Detalhes",
                        onClick: (event, rowData) =>
                          openTransactionDetails(rowData),
                      },
                    ]}
                    components={{
                      ...DEFAULT_TABLE_COMPONENTS,
                      Container: (props: any) => <div {...props}></div>,
                    }}
                    localization={TABLE_L10N_PTBR}
                    options={{
                      ...DEFAULT_TABLE_OPTIONS,
                      paging: false,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogCloseButton: {
      position: "absolute",
      right: "16px",
      top: "16px",
      color: theme.palette.grey[500],
    },
  })
);
