import { apiAxios as axios } from "../store/api/config";
import { parseISO } from "date-fns";
import { PagedResponse } from "./types/common";
import { urls } from "../store/api/urls";
import { Cooperative } from "./types/cooperative";

export interface ICooperativeService {
  getCooperatives: (
  ) => Promise<PagedResponse<Cooperative>>;
  getCooperative: (
    id: number
    ) => Promise<Cooperative>;
}


const CooperativeService: ICooperativeService = {
  
    getCooperatives: async (
    ) => {
    return new Promise((resolve, reject) => {
      axios.get(urls.COOPERATIVE)
        .then((response) => {
          const data: Cooperative[] = response.data.results.map((item: any) => {
            return {
              ...item,
              id: Number(item.id),
              created_at: parseISO(item.created_at),
            };
          });
          const result: PagedResponse<Cooperative> = {
            data: data,
            count: response.data.count,
          };
          resolve(result);
        })
        .catch((error) => reject(error));
    });
  },

  getCooperative: async (
    id: number
    ) => {
    return new Promise((resolve, reject) => {
      axios.get(`${urls.COOPERATIVE}${id}/`)
        .then((response) => {
          const data: Cooperative = {
              ...response.data,
              id: Number(response.data.id),
              created_at: parseISO(response.data.created_at),
            };
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  },

  
  

};

export default CooperativeService;
