import React from "react";
import { FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Button, MenuItem } from "@material-ui/core";
import { RemittanceStatus } from "../../services/types/remittance";
import { DEFAULT_LOCALE, CURRENT_DATE_FORMAT } from "../../i18n";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router";
import { encodeURLParams, URLParamType } from "../../utils/utils";

export interface IRemittanceFilterParams {
    number: string;
    sent_date: Date | null;
    liquidate_date: Date | null;
    status: RemittanceStatus | null;
}

export const INITIAL_FILTER_PARAMS: IRemittanceFilterParams = {
    number: "",
    sent_date: null,
    liquidate_date: null,
    status: null,
};

interface IRemittanceFilterProps {
    params?: IRemittanceFilterParams;
    onFilterChanged: (params: IRemittanceFilterParams) => void;
}

export const RemittanceFilter: React.FC<IRemittanceFilterProps> = ({
    params = INITIAL_FILTER_PARAMS,
    onFilterChanged,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    React.useEffect(() => {
        history.replace(encodeURLParams(history.location.pathname, params, [
            { "sent_date": URLParamType.DATE },
            { "liquidate_date": URLParamType.DATE }
        ]));
    }, [history, params]);

    const formik = useFormik<IRemittanceFilterParams>({
        initialValues: params,
        onSubmit: (values: IRemittanceFilterParams, formikHelpers: FormikHelpers<IRemittanceFilterParams>) => {
            onFilterChanged(values);
        },
        onReset: (values: IRemittanceFilterParams, formikHelpers: FormikHelpers<IRemittanceFilterParams>) => {
            onFilterChanged(INITIAL_FILTER_PARAMS);
            formikHelpers.setValues(INITIAL_FILTER_PARAMS);
        },
    });

    return (
        <div className="page-filter">
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <TextField
                            id="number"
                            label="Número"
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            select
                            id="status"
                            name="status"
                            label="Situação"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            variant="outlined"
                            fullWidth
                        >
                            <MenuItem value="">Todos</MenuItem>
                            <MenuItem value={RemittanceStatus.NOT_SENT}>
                                {t("remittance_status." + RemittanceStatus.NOT_SENT)}
                            </MenuItem>
                            <MenuItem value={RemittanceStatus.AWAITING_SETTLEMENT}>
                                {t("remittance_status." + RemittanceStatus.AWAITING_SETTLEMENT)}
                            </MenuItem>
                            <MenuItem value={RemittanceStatus.SETTLED}>
                                {t("remittance_status." + RemittanceStatus.SETTLED)}
                            </MenuItem>
                            <MenuItem value={RemittanceStatus.PARTIALLY_SETTLED}>
                                {t("remittance_status." + RemittanceStatus.PARTIALLY_SETTLED)}
                            </MenuItem>
                            <MenuItem value={RemittanceStatus.SETTLEMENT_FAILED}>
                                {t("remittance_status." + RemittanceStatus.SETTLEMENT_FAILED)}
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item md={3}>
                        <DatePicker
                            id="sent_date"
                            name="sent_date"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("sent_date", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.sent_date}
                            startDate={formik.values.sent_date}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_FORMAT}
                            isClearable
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data do Envio"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.sent_date}
                                helperText={formik.errors.sent_date}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <DatePicker
                            id="liquidate_date"
                            name="liquidate_date"
                            onChange={(val, event: React.SyntheticEvent) => {
                                formik.setFieldValue("liquidate_date", val);
                                event.preventDefault();
                            }}
                            maxDate={new Date()}
                            selected={formik.values.liquidate_date}
                            startDate={formik.values.liquidate_date}
                            selectsStart
                            locale={DEFAULT_LOCALE}
                            dateFormat={CURRENT_DATE_FORMAT}
                            isClearable
                            autoComplete="off"
                            wrapperClassName="MuiFormControl-fullWidth"
                            customInput={<TextField
                                label="Data da Liquidação"
                                variant="outlined"
                                size="small"
                                error={!!formik.errors.liquidate_date}
                                helperText={formik.errors.liquidate_date}
                                fullWidth />}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Pesquisar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="reset"
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
